import service from '@/services/index'

export default {
  state: {
    audits: [],
    audit_rincians: [],
  },
  getters: {
    searchAudit: state => id => state.audits.find(item => item.id == id),
  },
  mutations: {
    SET_AUDIT(state, datas) {
      state.audits = datas
    },
    SET_AUDIT_RINCIAN(state, datas) {
      state.audit_rincians = datas
    },
  },
  actions: {
    async getAudit({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-audit',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_AUDIT', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getAuditById({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-audit/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRincianAudit({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-audit-rincian',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_AUDIT_RINCIAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveAuditRincian({}, payload) {
      try {
        const config = {
          url: '/input/asset-audit-rincian',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveAudit({}, payload) {
      try {
        const config = {
          url: '/input/asset-audit',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async printAudit({}, id) {
      try {
        const config = {
          url: `/cetak-audit-penyimpanan-asset/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
  },
}
