import service from '@/services/index'

// Module Vuex affiliator/member
export default {
  namespaced: true,
  state: {
    datas: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
    SET_DATA_WILTER(state, data) {
      state.datas_wilter = data
    },
    SET_TOTAL_WILTER(state, total) {
      state.totals_wilter = total;
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/affiliator/member',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }

        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/affiliator/member/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getwilayahteritori({ commit }, params = {}) {
      try {
        const config = {
          url: '/affiliator/wilayah-teritori',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }

        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA_WILTER", result);
        commit("SET_TOTAL_WILTER", result_total);

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getwilayahmember({ commit }, params = {}) {
      try {
        const config = {
          url: '/affiliator/wilayah-member',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/affiliator/member',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updatemember({}, payload = {}) {
      try {
        const config = {
          url: '/affiliator/update-member',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async savekonfirmasi({}, payload = {}) {
      try {
        const config = {
          url: '/affiliator/konfirmasi',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async pindahmkt({}, payload = {}) {
      try {
        const config = {
          url: '/teritori-marketing',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
