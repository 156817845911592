export default {
  namespaced: true,
  state: {
    showModal: false,
    id: null,
  },
  actions: {},
  mutations: {
    TOGGLE_SHOW_MODAL(state, val) {
      state.showModal = val
    },
    SET_ACTIVE_ID(state, id) {
      state.id = id
    },
  },
}
