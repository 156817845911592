export default [
  {
    path: '/kendaraan-barang',
    name: 'route-kendaraan-barang',
    component: () => import('@/views/pages/kendaraan-barang/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Kendaraan Barang', // for name menu
      breadcrumb: [
        {
          text: 'Kendaraan Barang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kendaraan-barang/detail/:id',
    name: 'route-detail-kendaraan-barang',
    component: () => import('@/views/pages/kendaraan-barang/Detail.vue'),
    meta: {
      authRequired: true,
      parent: '/kendaraan-barang',
      breadcrumb: [
        {
          text: 'Kendaraan Barang',
          to: '/kendaraan-barang',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
]
