export default [
  {
    path: '/kendaraan/form-perbaikan',
    name: 'route-form-perbaikan-kendaraan',
    component: () => import('@/views/pages/perbaikan-kendaraan/List.vue'),
    meta: {
      pageTitle: 'Perbaikan & Pajak Kendaraan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Perbaikan & Pajak Kendaraan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kendaraan/form-perbaikan/:id',
    name: 'route-form-perbaikan-kendaraan-detail',
    component: () => import('@/views/pages/perbaikan-kendaraan/Store.vue'),
    meta: {
      parent: '/kendaraan/form-perbaikan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Perbaikan & Pajak Kendaraan',
          to: '/kendaraan/form-perbaikan',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
]
