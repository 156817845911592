export default [
  {
    path: '/shipping-order',
    component: () => import('@/views/pages/shipping-order/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Order Pengiriman',
      breadcrumb: [
        {
          text: 'Order Pengiriman',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-order/add',
    component: () => import('@/views/pages/shipping-order/Store.vue'),
    meta: {
      authRequired: true,
      parent: '/shipping-order',
      breadcrumb: [
        {
          text: 'Order Pengiriman',
          to: '/shipping-order',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-order/edit/:id',
    component: () => import('@/views/pages/shipping-order/Store.vue'),
    meta: {
      authRequired: true,
      parent: '/shipping-order',
      breadcrumb: [
        {
          text: 'Order Pengiriman',
          to: '/shipping-order',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shipping-order/detail/:id',
    component: () => import('@/views/pages/shipping-order/Detail.vue'),
    meta: {
      authRequired: true,
      parent: '/shipping-order',
      breadcrumb: [
        {
          text: 'Order Pengiriman',
          to: '/shipping-order',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
]
