export default [
    {
      path: "/shipping-assignment",
      name: "route-shipping-assignment",
      component: () => import("@/views/pages/shipping-assignment/List.vue"),
      meta: {
        module: "Tugas Pengiriman",
        authRequired: true,
        pageTitle: "Tugas Pengiriman",
        breadcrumb: [
          {
            text: "Tugas Pengiriman",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/aturan-tingkat-harga",
    //   name: "route-aturan-tingkat-harga",
    //   component: () => import("@/views/pages/shipping-assignment/aturan-tingkat-harga/List.vue"),
    //   meta: {
    //     module: "Tugas Pengiriman",
    //     authRequired: true,
    //     pageTitle: "Tugas Pengiriman",
    //     breadcrumb: [
    //       {
    //         text: "Tugas Pengiriman",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
  ];
  