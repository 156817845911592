export default [
  {
    path: '/level',
    name: 'route-level',
    component: () => import('@/views/pages/level/List.vue'),
    meta: {
      module: 'Level',
      authRequired: true,
      pageTitle: 'Level',
      breadcrumb: [
        {
          text: 'Level',
          active: true,
        },
      ],
    },
  },
]
