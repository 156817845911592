export default [
  {
    path: "/poin",
    name: "route-poin",
    component: () => import("@/views/pages/poin/Index.vue"),
    meta: {
      module: "Poin",
      authRequired: true,
      pageTitle: "Poin",
      breadcrumb: [
        {
          text: "Poin",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-affiliate",
    name: "route-komisi-affiliate",
    component: () => import("@/views/pages/komisi/affiliate/Index.vue"),
    meta: {
      module: "Komisi Affiliate",
      authRequired: true,
      pageTitle: "Komisi Affiliate",
      breadcrumb: [
        {
          text: "Komisi Affiliate",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-bulanan",
    name: "route-komisi-bulanan",
    component: () => import("@/views/pages/komisi/bulanan/Index.vue"),
    meta: {
      module: "Komisi Bulanan",
      authRequired: true,
      pageTitle: "Komisi Bulanan",
      breadcrumb: [
        {
          text: "Komisi Bulanan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-cabang",
    name: "route-komisi-cabang",
    component: () => import("@/views/pages/komisi/cabang/Index.vue"),
    meta: {
      module: "Komisi Cabang",
      authRequired: true,
      pageTitle: "Komisi Cabang",
      breadcrumb: [
        {
          text: "Komisi Cabang",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-mitra",
    name: "route-komisi-mitra",
    component: () => import("@/views/pages/komisi/mitra/Index.vue"),
    meta: {
      module: "Komisi Mitra",
      authRequired: true,
      pageTitle: "Komisi Mitra",
      breadcrumb: [
        {
          text: "Komisi Mitra",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penarikan-komisi",
    name: "route-penarikan-komisi",
    component: () => import("@/views/pages/komisi/penarikan/Index.vue"),
    meta: {
      module: "Penarikan Komisi",
      authRequired: true,
      pageTitle: "Penarikan Komisi",
      breadcrumb: [
        {
          text: "Komisi",
          to: "/komisi-affiliate",
        },
        {
          text: "Penarikan Komisi",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penarikan-komisi-cabang",
    name: "route-penarikan-komisi-cabang",
    component: () => import("@/views/pages/komisi/penarikan-cabang/Index.vue"),
    meta: {
      module: "Penarikan Komisi Cabang",
      authRequired: true,
      pageTitle: "Penarikan Komisi Cabang",
      breadcrumb: [
        {
          text: "Komisi Cabang",
          to: "/komisi-cabang",
        },
        {
          text: "Penarikan Komisi Cabang",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penarikan-komisi-bulanan",
    name: "route-penarikan-komisi-bulanan",
    component: () => import("@/views/pages/komisi/penarikan-bulanan/Index.vue"),
    meta: {
      module: "Penarikan Komisi Bulanan",
      authRequired: true,
      pageTitle: "Penarikan Komisi Bulanan",
      breadcrumb: [
        {
          text: "Komisi Bulanan",
          to: "/komisi-bulanan",
        },
        {
          text: "Penarikan Komisi Bulanan",
          active: true,
        },
      ],
    },
  },
  // PENGATURAN
  {
    path: "/pengaturan-komisi",
    name: "route-pengaturan-komisi",
    component: () => import("@/views/pages/pengaturan/komisi/Index.vue"),
    meta: {
      authRequired: true,
      module: "pengaturan-komisi",
      pageTitle: "Pengaturan Komisi",
      breadcrumb: [
        {
          text: "Pengaturan Komisi",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pengaturan-komisi-barang",
    name: "route-pengaturan-komisi-barang",
    component: () => import("@/views/pages/pengaturan/komisi-barang/Index.vue"),
    meta: {
      authRequired: true,
      module: "pengaturan-komisi-barang",
      pageTitle: "Pengaturan Komisi Dan Poin Barang",
      breadcrumb: [
        {
          text: "Pengaturan Komisi Dan Poin Barang",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-profesi",
    name: "route-komisi-profesi",
    component: () => import("@/views/pages/komisi/profesi/Index.vue"),
    meta: {
      module: "Komisi Profesi",
      authRequired: true,
      pageTitle: "Komisi Profesi",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/laba-seller",
    name: "route-laba-seller",
    component: () => import("@/views/pages/komisi/laba_seller/Index.vue"),
    meta: {
      module: "Laba Seller",
      authRequired: true,
      pageTitle: "Laba Seller",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-profesi-ajuanpenarikan",
    name: "route-komisi-profesi-ajuanpenarikan",
    component: () => import("@/views/pages/komisi/profesi/Penarikan.vue"),
    meta: {
      module: "Komisi Profesi",
      authRequired: true,
      pageTitle: "Komisi Profesi",
      breadcrumb: [
        {
          text: "list",
          to: "/komisi-profesi",
        },
        {
          text: "Penarikan",
          active: true,
        },
      ],
    },
  },
];
