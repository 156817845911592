import { Ability, AbilityBuilder } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const roles = localStorage.userRoles ? JSON.parse(localStorage.userRoles) : initialAbility
const abilities = []
const sbAbilities = []
if (roles) {
  // IMPORTANT: Subject Name must equal to Menu Name
  roles.map(item => {
    // if(item.menu) {
    // check first for prevent duplicate
    const subject = item.name
    abilities.push({
      action: 'read',
      subject,
    })
    const checkCreate = abilities.find(ab => ab.action == 'create' && ab.subject == subject)
    if (item.create && !checkCreate) {
      abilities.push({
        action: 'create',
        subject,
      })
    }

    const checkUpdate = abilities.find(ab => ab.action == 'update' && ab.subject == subject)

    if (item.update && !checkUpdate) {
      abilities.push({
        action: 'update',
        subject,
      })
    }

    const checkDelete = abilities.find(ab => ab.action == 'delete' && ab.subject == subject)
    if (item.delete && !checkDelete) {
      abilities.push({
        action: 'delete',
        subject,
      })
    }

    if (item.sub_menu) {
      item.sub_menu.map(sb => {
        const subject = sb.name
        sbAbilities.push({
          action: 'read',
          subject,
        })
        const checkCreate = sbAbilities.find(ab => ab.action == 'create' && ab.subject == subject)
        if (sb.create && !checkCreate) {
          sbAbilities.push({
            action: 'create',
            subject,
          })
        }

        const checkUpdate = sbAbilities.find(ab => ab.action == 'update' && ab.subject == subject)

        if (sb.update && !checkUpdate) {
          sbAbilities.push({
            action: 'update',
            subject,
          })
        }

        const checkDelete = sbAbilities.find(ab => ab.action == 'delete' && ab.subject == subject)
        if (sb.delete && !checkDelete) {
          sbAbilities.push({
            action: 'delete',
            subject,
          })
        }
      })
    }
    // }
  })
}
localStorage.setItem('gabungan', JSON.stringify([...abilities, ...sbAbilities]))
export default new Ability([...abilities, ...sbAbilities])
