import service from '@/services'

export default {
  state: {
    stocks: [],
    histories: [],
  },
  getters: {
    searchStock: state => id => state.stocks.find(item => item.id == id),
  },
  mutations: {
    SET_STOCK(state, datas) {
      state.stocks = datas
    },
    SET_RIWAYAT_PENYIMPANAN(state, datas) {
      state.histories = datas
    },
  },
  actions: {
    async getStock({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-penyimpanan',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_STOCK', result)
        return Promise.resolve(JSON.parse(JSON.stringify(result)))
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRiwayatPenyimpanan({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-riwayat-penyimpanan',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_RIWAYAT_PENYIMPANAN', result)
        return Promise.resolve(JSON.parse(JSON.stringify(result)))
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePenyimpanan({}, payload) {
      try {
        const config = {
          url: '/input/asset-penyimpanan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async savePenyimpananEmpty({}, payload) {
      try {
        const config = {
          url: '/input/asset-penyimpanan-create',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async cancelPenyimpanan({}, payload) {
      try {
        const config = {
          url: '/input/asset-riwayat-penyimpanan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getStockById({}, id) {
      try {
        const config = {
          url: `/asset-penyimpanan/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
