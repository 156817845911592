import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getJenis: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
  },
  actions: {
    async getDataById({}, id) {
      try {
        const config = {
          baseURL : process.env.VUE_APP_API2,
          url: `/shipping-package/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data.results);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          baseURL : process.env.VUE_APP_API2,
          url: "/shipping-package",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.results ? response.data.results : [];
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      console.log("payload",payload);
      const id = payload.id;
      const type = payload.fungsi;
      try {
        // If id is provided, set the URL to include the id and use the PUT method
        let url = "/shipping-package";
        let method = "post";
    
        if (type == 2) {
          url = `/shipping-package/${id}`;
          method = "put";
          // Remove id from payload
          const { id: _, ...restPayload } = payload;
          payload = restPayload;
          console.log(payload);
        }
        if (type == 1) {
          url = `/shipping-package/${id}`;
          method = "delete";
        }
    
        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: url,
          method: method,
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
    
        const response = await service.sendRequest(config);
    
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteRule({}, id = {}) {
      try {
        // If id is provided, set the URL to include the id and use the PUT method
        let url = `/price-tier-rules/${id}`;
        let method = "delete";
  
        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: url,
          method: method,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
    
        const response = await service.sendRequest(config);
    
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async createRule({}, payload = {}) {
      try {
        let url = `/price-tier-rules`;
        let method = "post";
  
        const config = {
          baseURL: process.env.VUE_APP_API2,
          url: url,
          method: method,
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
    
        const response = await service.sendRequest(config);
    
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    
  },
};
