export default [
  {
    path: "/finance/komisi",
    name: "route-finance-komisi",
    component: () => import("@/views/pages/finance/komisi/List.vue"),
    meta: {
      module: "Komisi Finance",
      authRequired: true,
      pageTitle: "Komisi",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/finance/komisi/pengaturan",
    name: "route-finance-komisi-pengaturan",
    component: () => import("@/views/pages/finance/komisi/Pengaturan.vue"),
    meta: {
      module: "Komisi",
      authRequired: true,
      pageTitle: "Pengaturan Komisi",
      breadcrumb: [
        {
          text: "Atur",
          active: true,
        },
      ],
    },
  },
  {
    path: "/riwayat-reward",
    name: "route-riwayat-reward",
    component: () => import("@/views/pages/reward/riwayat/Index.vue"),
    meta: {
      module: "Reward",
      authRequired: true,
      pageTitle: "Reward",
      breadcrumb: [
        {
          text: "Reward",
          to: "/reward",
        },
        {
          text: "Riwayat Reward",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pengaturan-poin",
    name: "route-pengaturan-poin",
    component: () => import("@/views/pages/pengaturan/poin/Index.vue"),
    meta: {
      authRequired: true,
      module: "pengaturan-poin",
      pageTitle: "Pengaturan poin",
      breadcrumb: [
        {
          text: "Pengaturan Poin",
          active: true,
        },
      ],
    },
  },
];
