import axios from '@/libs/axios'
// refs: https://axios-http.com/docs/req_config

/**
 *
 * const initConfig = {
 *    url: '/jabatan',
 *    method: 'get',
 *    headers,
 *    params,
 *    body
 * }
 */

export default {
  async sendRequest(config = {}) {
    try {
      const response = await axios.request(config)

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },
}
