export default [
  // Akun
  {
    path: '/rekening',
    name: 'route-rekening',
    component: () => import('@/views/pages/rekening/List.vue'),
    meta: {
      module: 'Rekening',
      authRequired: true,
      pageTitle: 'Rekening',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
