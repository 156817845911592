export default [

  {
    path: '/disposal-rusak',
    name: 'route-disposal-rusak',
    component: () => import('@/views/pages/disposal-rusak/List.vue'),
    meta: {
      authRequired: true,
      module: 'Disposal Rusak',
      pageTitle: 'Disposal Rusak',
      breadcrumb: [
        {
          text: 'Disposal Rusak',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/disposal-rusak/detail/:id',
  //   name: 'route-disposal-rusakdetail',
  //   component: () => import('@/views/pages/disposal-rusak/DetailList.vue'),
  //   meta: {
  //     parent: '/disposal-rusak',
  //     authRequired: true,
  //     module: 'Disposal Rusak',
  //     pageTitle: 'Disposal Rusak',
  //     breadcrumb: [
  //       {
  //         text: 'Disposal Rusak',
  //         to: '/disposal-rusak',
  //         // active: true,
  //       },
  //       {
  //         text: 'Detail Barang',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
