export default [
  {
    path: '/menu',
    name: 'route-menu',
    component: () => import('@/views/pages/menu/List.vue'),
    meta: {
      module: 'Menu',
      authRequired: true,
      pageTitle: 'Menu',
      breadcrumb: [
        {
          text: 'Menu',
          active: true,
        },
      ],
    },
  },
]
