export default [
  // Diskon
  {
    path: '/diskon',
    name: 'route-diskon',
    component: () => import('@/views/pages/diskon/List.vue'),
    meta: {
      module: 'Diskon',
      authRequired: true,
      pageTitle: 'Diskon',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
