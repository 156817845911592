export default [
  {
    path: "/reward-master",
    name: "route-reward-master",
    component: () => import("@/views/pages/reward-master/List.vue"),
    meta: {
      module: "Reward Master",
      authRequired: true,
      pageTitle: "Reward Master",
      breadcrumb: [
        {
          text: "Reward Master",
          active: true,
        },
      ],
    },
  },
  {
    path: "/reward",
    name: "route-reward",
    component: () => import("@/views/pages/reward/List.vue"),
    meta: {
      module: "Reward",
      authRequired: true,
      pageTitle: "Reward",
      breadcrumb: [
        {
          text: "Reward",
          active: true,
        },
      ],
    },
  },
  {
    path: "/riwayat-reward",
    name: "route-riwayat-reward",
    component: () => import("@/views/pages/reward/riwayat/Index.vue"),
    meta: {
      module: "Reward",
      authRequired: true,
      pageTitle: "Reward",
      breadcrumb: [
        {
          text: "Reward",
          to: "/reward",
        },
        {
          text: "Riwayat Reward",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pengaturan-poin",
    name: "route-pengaturan-poin",
    component: () => import("@/views/pages/pengaturan/poin/Index.vue"),
    meta: {
      authRequired: true,
      module: "pengaturan-poin",
      pageTitle: "Pengaturan poin",
      breadcrumb: [
        {
          text: "Pengaturan Poin",
          active: true,
        },
      ],
    },
  },
];
