import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
    totals: 0,
    records: null,
  },
  getters: {
    getPoin: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_TOTAL(state, total) {
      state.pointotals = total;
    },
    SET_DATA_POIN(state, data) {
      state.poindatas = data;
    },
    SET_TOTAL_POIN(state, total) {
      state.pointotals = total;
    },
  },
  actions: {
    async getDataById({}, id) {
      try {
        const config = {
          url: `/poin/index/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/poin/index",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/poin/input",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getRekap({ commit }, params = {}) {
      try {
        const config = {
          url: "/poin/index-rekap",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data ? response.data : [];

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataTukarPoin({ commit }, params = {}) {
      try {
        const config = {
          url: "/penukaran-reward",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA_POIN", result);
        commit("SET_TOTAL_POIN", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataTukarPoinId({}, id) {
      try {
        const config = {
          url: `/penukaran-reward/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async konfirmasi({}, payload = {}) {
      try {
        const config = {
          url: "/konfirmasi-pengajuan-reward",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async hapus({}, payload = {}) {
      try {
        const config = {
          url: "/hapus-pengajuan-reward",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async pengajuan({}, payload = {}) {
      try {
        const config = {
          url: "/pengajuan-reward",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
