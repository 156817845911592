export default [
  {
    path: '/bop',
    name: 'route-bop',
    component: () => import('@/views/pages/bop/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Daftar BOP', // for name menu
      breadcrumb: [
        {
          text: 'Daftar BOP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bop/detail/:id',
    name: 'route-bop-detail',
    component: () => import('@/views/pages/bop/Info.vue'),
    meta: {
      parent: '/bop',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Daftar BOP',
          to: '/bop',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
]
