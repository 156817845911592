export default [
  {
    path: '/laporan/jurnal',
    name: 'laporan-jurnal',
    component: () => import('@/views/pages/laporan/Journal.vue'),
    meta: {
      api: '/cetak-jurnal',
      authRequired: true,
      component: 'ProfitAndLoss',
      pageTitle: 'Laporan Jurnal',
      breadcrumb: [
        {
          text: 'Jurnal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan/perubahan-modal',
    name: 'laporan-perubahan-modal',
    component: () => import('@/views/pages/laporan/CapitalChanges.vue'),
    meta: {
      api: '/cetak-perubahan-modal',
      authRequired: true,
      component: 'CapitalChanges',
      pageTitle: 'Laporan Perubahan Modal',
      breadcrumb: [
        {
          text: 'Perubahan Modal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan/droping-sales',
    name: 'laporan-droping-sales',
    component: () => import('@/views/pages/laporan/DropingSales.vue'),
    meta: {
      api: '/excel-rekap-dropping',
      authRequired: true,
      component: 'DropingSales',
      pageTitle: 'Laporan Droping Sales',
      breadcrumb: [
        {
          text: 'Droping Sales',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan/laba-rugi',
    name: 'laporan-laba-rugi',
    component: () => import('@/views/pages/laporan/ProfitAndLoss.vue'),
    meta: {
      api: '/cetak-laba-rugi',
      component: 'ProfitAndLoss',
      authRequired: true,
      pageTitle: 'Laporan Laba Rugi',
      breadcrumb: [
        {
          text: 'Laporan Laba Rugi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan/sales',
    name: 'laporan-sales',
    component: () => import('@/views/pages/laporan/Sales.vue'),
    meta: {
      api: '/cetak-laporan-sales',
      component: 'Sales',
      authRequired: true,
      pageTitle: 'Laporan Sales',
      breadcrumb: [
        {
          text: 'Laporan Sales',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laporan/lpj',
    name: 'laporan-lpj',
    component: () => import('@/views/pages/laporan/LPJ.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Laporan LPJ',
      breadcrumb: [
        {
          text: 'Laporan LPJ',
          active: true,
        },
      ],
    },
  },
]
