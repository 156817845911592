import service from '@/services'
import QueryString from 'qs'

// Module Vuex stok-opname
export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getData: state => id => state.datas.find(item => item.id == id),
    getDataSo: state => id => state.datas2.find(item => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_DATA2(state, data) {
      state.datas2 = data
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
    SET_TOTAL2(state, total) {
      state.totals2 = total;
    },
  },
  actions: {
    async printAjuan({}, bop_id) {
      try {
        const config = {
          url: `/toko/cetak-stok-opname/${bop_id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/toko/stok-opname',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/toko/stok-opname/${id}`,
          method: 'get',
          // params,
          // paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/toko/input/stok-opname',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataSo({ commit }, params = {}) {
      try {
        const config = {
          url: '/toko/stok-opname-rincian',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA2", result);
        commit("SET_TOTAL2", result_total);

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveSo({}, payload = {}) {
      try {
        const config = {
          url: '/toko/input/stok-opname-rincian',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
