import QueryString from 'qs'
import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
    dataApproved: [],
    gudangOptions: [],
  },
  getters: {
    search(state) {
      return id => state.datas.find(bop => bop.id == id)
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_GUDANG_OPTIONS(state, data) {
      state.gudangOptions = data
    },
    SET_DATA_APPROVED(state, data) {
      state.dataApproved = data
    },
  },
  actions: {
    async approve({}, payload) {
      try {
        const config = {
          url: '/input/retur-approve',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async lapor({}, payload) {
      try {
        const config = {
          url: '/retur-konsumen-laporkan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async checkout({}, payload) {
      try {
        const config = {
          url: '/retur-konsumen-checkout',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/retur-konsumen',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getApproved({ commit }, params = {}) {
      try {
        const config = {
          url: '/retur-approve',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA_APPROVED', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/retur-konsumen',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/retur-konsumen',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
