import service from '@/services'

export default {
  state: {
    peminjamans: [],
    history_peminjamans: [],
    penyimpanans: [],
  },
  getters: {
    searchPeminjaman: state => id => state.peminjamans.find(item => item.id == id),
  },
  mutations: {
    SET_PEMINJAMAN(state, datas) {
      state.peminjamans = datas
    },
    SET_HISTORY_PEMINJAMAN(state, datas) {
      state.history_peminjamans = datas
    },
    SET_PENYIMPANAN_PEMINJAMAN(state, datas) {
      state.penyimpanans = datas
    },
  },
  actions: {
    async getPeminjaman({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-peminjaman',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_PEMINJAMAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRiwayatPeminjaman({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-riwayat-peminjaman',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_HISTORY_PEMINJAMAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPeminjamanById({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-peminjaman/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePeminjaman({}, payload) {
      try {
        const config = {
          url: '/input/asset-peminjaman',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async cancelPeminjaman({}, payload) {
      try {
        const config = {
          url: '/input/asset-riwayat-peminjaman',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(error)
      }
    },
  },
}
