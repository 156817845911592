import service from '@/services'
import ability from './ability'
import { getSubject } from './utils'

export const canNavigate = to => to.matched.some(async route => {
  const canSetting = sessionStorage.getItem('canSetting') == 'true' || false
  if ((route.path == '/setting' && canSetting) || route.path == '/rank-sales') {
    return true
  }

  const subject = getSubject(route)
  return ability.can(route.meta.action || 'read', subject)
})

export const _ = undefined
