import service from '@/services'

export default {
  state: {
    disposals: [],
  },
  getters: {
    searchDisposal: state => id => state.disposals.find(item => item.id == id),
  },
  mutations: {
    SET_DISPOSAL(state, datas) {
      state.disposals = datas
    },
  },
  actions: {
    async getDisposal({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/asset-disposal',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DISPOSAL', result)
        return Promise.resolve(JSON.parse(JSON.stringify(result)))
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getDisposalById({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-disposal/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveDisposal({}, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-disposal',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async printDisposal({}, params) {
      try {
        const config = {
          url: '/cetak-asset-disposal',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
  },
}
