export default [
  {
    path: '/hutang-perusahaan',
    name: 'hutang-perusahaan',
    component: () => import('@/views/pages/hutang-perusahaan/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Utang Perusahaan',
      breadcrumb: [
        {
          text: 'Utang Perusahaan',
          active: true,
        },
      ],
    },
  },
]
