import service from '@/services'

export default {
  state: {
    penyusutans: [],
  },
  getters: {
    searchPenyusutan: state => id => state.penyusutans.find(item => item.id == id),
  },
  mutations: {
    SET_PENYUSUTAN(state, datas) {
      state.penyusutans = datas
    },
  },
  actions: {
    async getPenyusutan({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/asset-penyusutan',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_PENYUSUTAN', result)

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPenyusutanById({ commit }, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-penyusutan/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePenyusutan({ commit }, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-penyusutan',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getCekBiayaPenyusutan({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/cek-biaya-penyusutan-asset',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        // const result = response.data && response.data.data ? response.data.data : []
        // commit('SET_PENYUSUTAN', result)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveJurnal({ commit }, payload) {
      try {
        const config = {
          method: 'post',
          url: '/asset-penyusutan-jurnal',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveJurnalCancel({ commit }, payload) {
      try {
        const config = {
          method: 'post',
          url: '/asset-penyusutan-cancel',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
