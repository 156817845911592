import service from '@/services/index'

export default {
  state: {
    penjualans: [],
    rincians: [],
    currentTotalPenjualan: 0,
  },
  getters: {
    searchPenjualan: state => id => state.penjualans.find(item => item.id == id),
  },
  mutations: {
    SET_PENJUALAN(state, datas) {
      state.penjualans = datas
    },
    SET_PENJUALAN_RINCIAN(state, datas) {
      state.rincians = datas
    },
    SET_CURRENT_TOTAL_PENJUALAN(state, data) {
      state.currentTotalPenjualan = data
    },
  },
  actions: {
    async getPenjualan({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-penjualan',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_PENJUALAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPenjualanById({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-penjualan/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRincianPenjualan({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-penjualan-rincian',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_PENJUALAN_RINCIAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePenjualanRincian({}, payload) {
      try {
        const config = {
          url: '/input/asset-penjualan-rincian',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async savePenjualan({}, payload) {
      try {
        const config = {
          url: '/input/asset-penjualan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async confirmPenjualan({}, payload) {
      try {
        const config = {
          url: '/input/asset-penjualan-konfirm',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
