import service from '@/services'

export default {
  state: {
    pengembalians: [],
  },
  getters: {
    searchPengembalian: state => id => state.pengembalians.find(item => item.id == id),
  },
  mutations: {
    SET_PENGEMBALIAN(state, datas) {
      state.pengembalians = datas
    },
  },
  actions: {
    async getPengembalian({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/asset-pengembalian',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_PENGEMBALIAN', result)

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPengembalianById({ commit }, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-pengembalian/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePengembalian({ commit }, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-pengembalian',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
